import { Navbar, Container, Nav } from "react-bootstrap";
import { useState, useEffect } from "react";
import logo from '../assets/img/logo.svg';
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';
import { HashLink } from 'react-router-hash-link';
import { useNavigate } from 'react-router-dom';
import 'animate.css';

export const NavBar = () => {
    const [activeLink, setActiveLink] = useState('home');
    const [scrolled, setScrolled] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    const navigate = useNavigate();
 
    useEffect(() => {
        const onScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }

            const sections = ['home', 'skills', 'projects', 'experiences', 'certificates', 'connect'];
            let currentSection = '';

            sections.forEach(section => {
                const element = document.getElementById(section);
                if (element) {
                    const { top, bottom } = element.getBoundingClientRect();
                    if (top <= window.innerHeight / 2 && bottom >= window.innerHeight / 2) {
                        currentSection = section;
                    }
                }
            });

            if (currentSection && currentSection !== activeLink) {
                setActiveLink(currentSection);
                navigate(`#${currentSection}`);
            }
        };


        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [activeLink, navigate])

    const onUpdateActiveLink = (value) => {
        setActiveLink(value);
        setIsExpanded(false);
    }

    return (
        <Navbar expand="lg" className={scrolled ? "scrolled" : ""} expanded={isExpanded}
            onToggle={(expanded) => setIsExpanded(expanded)}>
            <Container>
                <Navbar.Brand href="#home">
                    <img src={logo} alt="Logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav">
                    <span className="navbar-toggler-icon"></span>
                </Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav" className={isExpanded ? "animate__animated animate__fadeInDown" : ""}>
                    <Nav className="me-auto">
                        <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={
                            () => onUpdateActiveLink('home')
                        }>Home</Nav.Link>
                        <Nav.Link href="#skills" className={activeLink === 'skills' ? 'active navbar-link' : 'navbar-link'} onClick={
                            () => onUpdateActiveLink('skills')
                        }>Skills</Nav.Link>
                        <Nav.Link href="#projects" className={activeLink === 'projects' ? 'active navbar-link' : 'navbar-link'} onClick={
                            () => onUpdateActiveLink('projects')
                        }>Projects</Nav.Link>
                        <Nav.Link href="#experiences" className={activeLink === 'experiences' ? 'active navbar-link' : 'navbar-link'} onClick={
                            () => onUpdateActiveLink('experiences')
                        }>Experiences</Nav.Link>
                        <Nav.Link href="#certificates" className={activeLink === 'certificates' ? 'active navbar-link' : 'navbar-link'} onClick={
                            () => onUpdateActiveLink('certificates')
                        }>Certificates</Nav.Link>
                    </Nav>

                    <span className="navbar-text">
                        <div className="social-icon">
                            <a href="https://www.linkedin.com/in/michael-lambo/" target="_blank" rel="noopener noreferrer"><img src={navIcon1} alt="" /></a>
                            <a href="https://github.com/mikelambo7" target="_blank" rel="noopener noreferrer"><img src={navIcon2} alt="" /></a>
                            <a href="https://www.instagram.com/mic_lambo/" target="_blank" rel="noopener noreferrer"><img src={navIcon3} alt="" /></a>
                        </div>
                        <HashLink to='#connect'>
                            <button className="vvd"><span>Let’s Connect</span></button>
                        </HashLink>
                    </span>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}
