import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import java from "../assets/img/java.svg";
import python from "../assets/img/python.svg";
import javascript from "../assets/img/javascript.svg";
import kotlin from "../assets/img/kotlin.svg";
import typescript from "../assets/img/typescript.svg";
import dart from "../assets/img/dart.svg";
import sql from "../assets/img/sql.svg";
import r from "../assets/img/r.svg";
import html from "../assets/img/html.svg";
import css from "../assets/img/css.svg";
import react from "../assets/img/react.svg";
import soy from "../assets/img/soy.svg";
import jetpack from "../assets/img/jetpack.svg";
import android from "../assets/img/android.svg";
import flutter from "../assets/img/flutter.svg";
import firebase from "../assets/img/firebase.svg";
import figma from "../assets/img/figma.svg";
import mediapipe from "../assets/img/mediapipe.svg";
import tensorflow from "../assets/img/tensorflow.svg";
import opencv from "../assets/img/opencv.svg";
import canva from "../assets/img/canva.svg";
import mongodb from "../assets/img/mongodb.svg";
import microsoft from "../assets/img/microsoft.svg";
import colorSharp from "../assets/img/color-sharp.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { useState, useEffect } from "react";

export const Skills = () => {
    const [key, setKey] = useState("first");

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    useEffect(() => {
        setKey(key);
    }, [key]); 

    return (
        <section className="skill" id="skills">
            <Container>
                <Row>
                    <Col>
                        <TrackVisibility>
                            {({ isVisible }) =>
                                <div className={isVisible ? "animate__animated animate__pulse" : ""}>
                                    <div className="skill-bx">
                                        <h2>
                                            Skills
                                        </h2>
                                        <Tab.Container id="skills-tabs" activeKey={key} onSelect={(k) => setKey(k)}>
                                            <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center flex-column flex-md-row" id="pills-tab">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="first">Programming Languages</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="second">Web/Mobile Development</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="third">Tools / Frameworks</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="first">
                                                    <Carousel responsive={responsive} infinite={true} className="skill-slider">
                                                        <div className="item">
                                                            <img src={java} alt="Skill" />
                                                            <h5>Java</h5>
                                                        </div>
                                                        <div className="item">
                                                            <img src={python} alt="Skill" />
                                                            <h5>Python</h5>
                                                        </div>
                                                        <div className="item">
                                                            <img src={javascript} alt="Skill" />
                                                            <h5>JavaScript</h5>
                                                        </div>
                                                        <div className="item">
                                                            <img src={kotlin} alt="Skill" />
                                                            <h5>Kotlin</h5>
                                                        </div>
                                                        <div className="item">
                                                            <img src={typescript} alt="Skill" />
                                                            <h5>Typescript</h5>
                                                        </div>
                                                        <div className="item">
                                                            <img src={dart} alt="Skill" />
                                                            <h5>Dart</h5>
                                                        </div>
                                                        <div className="item">
                                                            <img src={sql} alt="Skill" />
                                                            <h5>SQL</h5>
                                                        </div>
                                                        <div className="item">
                                                            <img src={r} alt="Skill" />
                                                            <h5>R</h5>
                                                        </div>
                                                    </Carousel>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="second">
                                                    <Carousel responsive={responsive} infinite={true} className="skill-slider">
                                                        <div className="item">
                                                            <img src={html} alt="Skill" />
                                                            <h5>HTML</h5>
                                                        </div>
                                                        <div className="item">
                                                            <img src={css} alt="Skill" />
                                                            <h5>CSS</h5>
                                                        </div>
                                                        <div className="item">
                                                            <img src={react} alt="Skill" />
                                                            <h5>React</h5>
                                                        </div>
                                                        <div className="item">
                                                            <img src={soy} alt="Skill" />
                                                            <h5>Soy</h5>
                                                        </div>
                                                        <div className="item">
                                                            <img src={jetpack} alt="Skill" />
                                                            <h5>JetPack Compose</h5>
                                                        </div>
                                                        <div className="item">
                                                            <img src={android} alt="Skill" />
                                                            <h5>Android Studio</h5>
                                                        </div>
                                                        <div className="item">
                                                            <img src={flutter} alt="Skill" />
                                                            <h5>Flutter</h5>
                                                        </div>
                                                    </Carousel>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="third">
                                                    <Carousel responsive={responsive} infinite={true} className="skill-slider">
                                                        <div className="item">
                                                            <img src={firebase} alt="Skill" />
                                                            <h5>Firebase</h5>
                                                        </div>
                                                        <div className="item">
                                                            <img src={figma} alt="Skill" />
                                                            <h5>Figma</h5>
                                                        </div>
                                                        <div className="item">
                                                            <img src={mediapipe} alt="Skill" />
                                                            <h5>MediaPipe</h5>
                                                        </div>
                                                        <div className="item">
                                                            <img src={tensorflow} alt="Skill" />
                                                            <h5>TensorFlow.js</h5>
                                                        </div>
                                                        <div className="item">
                                                            <img src={opencv} alt="Skill" />
                                                            <h5>OpenCV</h5>
                                                        </div>
                                                        <div className="item">
                                                            <img src={canva} alt="Skill" />
                                                            <h5>Canva</h5>
                                                        </div>
                                                        <div className="item">
                                                            <img src={mongodb} alt="Skill" />
                                                            <h5>MongoDB</h5>
                                                        </div>
                                                        <div className="item">
                                                            <img src={microsoft} alt="Skill" />
                                                            <h5>Microsoft Office Suite</h5>
                                                        </div>
                                                    </Carousel>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Tab.Container>

                                    </div>
                                </div>
                            }
                        </TrackVisibility>
                    </Col>
                </Row>
            </Container>
            <img className="background-image-left" src={colorSharp} alt="Background" />
        </section>
    )
}