import React from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import "animate.css";
import TrackVisibility from "react-on-screen";

export const WorkExperience = () => {
  const experiences = {
    google: [
      {
        title: "Google – SWE Intern",
        date: "May - August 2024",
        location: "Sunnyvale, CA",
        points: [
          "Designed and implemented migration for attachment chips that appear in Gmail's inbox landing page, replacing custom Gmail code with cross-product library.",
          "Built upon cross-product library to develop new functionality for Gmail attachment chips.",
          "Collaborated across Google Workspace teams.",
          "Set up experiments for changes using Google and Gmail's experiment frameworks."
        ]
      },
      {
        title: "Google – STEP Intern",
        date: "May - August 2023",
        location: "Cambridge, MA",
        points: [
          "Enhanced user flows within Google’s “remove results about you” feature, which allows users to request the removal of information from the web. ",
          "Implemented features that actively streamlined the request appeal process for users.",
          "In general, 61% of users have their requests denied, yet only 6% file appeals",
          "Goal - Optimize the appeal process for users to significantly increase the rate of appeals being filed.",
          "Worked in Boq Web and incorporated Soy templating, Python, Typescript, and Flag guarding techniques."
        ]
      },
      {
        title: "Google – STEP Intern",
        date: "May - August 2022",
        location: "Mountain View, CA",
        points: [
          "Developed an internal web tool using Flutter that allows teams at Google to test their features and prototypes.",
          "Designed and implemented a feature specification form that generates a QR Code for displaying a feature upon scanning.",
          "Utilized Flutter and Dart programming in Android Studio."
        ]
      }
    ],
    xavier: [
      {
        title: "Xavier University of Louisiana – New Orleans, LA",
        date: "January 2022 – December 2024",
        location: "Student Library Assistant",
        points: [
          "Assisted as a student assistant for the school library’s Data Visualization Lab and Innovation Studio.",
          "Provided support and guidance on data visualization tools.",
          "Promoted academic research through lab resources."
        ]
      }
    ]
  };

  return (
    <section className="work-experience" id="experiences">
      <Container>
        <Row>
          <Col>
            <TrackVisibility>
              {({ isVisible }) => (
                <div className={isVisible ? "animate__animated animate__pulse" : ""}>
                  <h2>Work Experience</h2>
                </div>
              )}
            </TrackVisibility>
            <Tab.Container defaultActiveKey="google">
              <Row>
                <Col sm={12}>
                  <Nav variant="pills" className="justify-content-center pills-container">
                    <Nav.Item>
                      <Nav.Link eventKey="google">Google</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="xavier">Xavier University</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={12}>
                  <Tab.Content>
                    <Tab.Pane eventKey="google">
                      {experiences.google.map((exp, index) => (
                        <div className={`work-entry ${index === 0 ? 'swe' : index === 1 ? 'step2' : 'step1'}`} key={index}>
                          <div className="experience-container">
                            <h3>{exp.title}</h3>
                            <p>{exp.date} | {exp.location}</p>
                            <ul>
                              {exp.points.map((point, idx) => (
                                <li key={idx}>{point}</li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      ))}
                    </Tab.Pane>
                    <Tab.Pane eventKey="xavier">
                      {experiences.xavier.map((exp, index) => (
                        <div className="work-entry xav" key={index}>
                          <div className="experience-container">
                            <h3>{exp.title}</h3>
                            <p>{exp.date} | {exp.location}</p>
                            <ul>
                              {exp.points.map((point, idx) => (
                                <li key={idx}>{point}</li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      ))}
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
