import { Container, Row, Col } from "react-bootstrap";
import logo from '../assets/img/logo.svg';
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';

export const Footer = () => {
    return (
        <footer className="footer">
            <Container>
                <Row className="align-item-center">
                    <Col sm={6}>
                        <img src={logo} alt="logo" />
                    </Col>
                    <Col sm={6} className="text-center text-sm-end">
                        <div className="social-icon">
                            <a href="https://www.linkedin.com/in/michael-lambo/" target="_blank" rel="noopener noreferrer"><img src={navIcon1} alt="" /></a>
                            <a href="https://github.com/mikelambo7" target="_blank" rel="noopener noreferrer"><img src={navIcon2} alt="" /></a>
                            <a href="https://www.instagram.com/mic_lambo/" target="_blank" rel="noopener noreferrer"><img src={navIcon3} alt="" /></a>
                        </div>
                        <p className="copyright">CopyRight 2024. All Right Reserved</p>
                    </Col>
                    <Col sm={12}>
                        <p className="footer-text">Designed & <span role="img" aria-label="Coded">👨‍💻</span> by Michael Lambo</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}