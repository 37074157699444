import { useState } from "react";
import { Container, Row, Col, Tab } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import colorSharp2 from "../assets/img/color-sharp2.png";
import focusing from "../assets/img/someone_focusing.jpg"
import trigger_word from "../assets/img/trigger-word.png"
import date_format from "../assets/img/date-format.png"
import emoji_predictor from "../assets/img/emoji-predictor.png"
import jazz_music from "../assets/img/jazz-music.jpg"
import text_generator from "../assets/img/dinosaur-generator.png"
import cat_or_non_cat from "../assets/img/catVSnoncat.png"
import event_scheduler from "../assets/img/event_scheduler.png"
import food_order from "../assets/img/food_order.png"
import nachos from "../assets/img/nachos.png"
import clean_kangaroo from "../assets/img/clean kangaroo.png"
import yt_clone from "../assets/img/yt-clone.png"
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {
    const [showMore, setShowMore] = useState(false);

    const projects = [
        {
            title: "Focus Buddy",
            description: "Developed a web application that utilizes OpenCV's Haar cascade classifiers for real-time face detection and gaze estimation to help users maintain focus during various activities. The app tracks attention span and provides alerts when users become distracted. Integrated a dashboard for reviewing session statistics and visualizing focus trends over time, with session data securely stored in a database.",
            imgUrl: focusing,
        },
        {
            title: "Trigger Word Detection",
            description: "Developed a deep learning model for trigger word detection, similar to wake-word systems like 'Hey Siri' or 'OK Google.' Implemented a pipeline to preprocess audio into spectrograms and trained a neural network with Conv1D and GRU layers to detect the trigger word. Optimized the model for real-time inference and integrated post-processing to overlay a chime sound upon detection.",
            imgUrl: trigger_word,
        },
        {
            title: "Date Format Translation",
            description: "Developed a sequence-to-sequence attention model to translate human-readable dates (e.g., 'Tue 5th of April 09') into machine-readable format (e.g., '2009-04-05'). Leveraged an attention mechanism to dynamically focus on relevant parts of the input sequence, improving accuracy for varied date formats.",
            imgUrl: date_format,
        },
        {
            title: "Emoji Predictor",
            description: "Built an LSTM-based model to predict emojis from text using pre-trained GloVe embeddings. Developed a Keras architecture with LSTMs, dropout, and softmax for contextual understanding. Optimized performance through gradient descent and hyperparameter tuning, achieving 80-95% accuracy. Demonstrated the model’s ability to generalize unseen words to relevant emojis.",
            imgUrl: emoji_predictor,
        },
        {
            title: "Jazz Music Generator",
            description: "Built an LSTM-based model to generate jazz music by learning patterns from MIDI data. Trained an RNN to predict musical notes, using softmax sampling for variation. Applied gradient clipping and hyperparameter tuning to produce smooth, natural-sounding jazz solos.",
            imgUrl: jazz_music,
        },
        {
            title: "Character-Level Text Generator",
            description: "Built a character-level LSTM model to generate dinosaur names. Preprocessed text, tokenized characters, and trained an RNN with softmax sampling for variation. Used gradient clipping and hyperparameter tuning to improve coherence, producing creative and plausible names.",
            imgUrl: text_generator,
        },
        {
            title: "Binary Image Classifier",
            description: "Developed a binary image classifier using a two-layer neural network. Preprocessed images by flattening and normalizing pixel values, implemented forward and backward propagation, and used gradient descent to minimize cross-entropy loss. Tuned hyperparameters, including learning rate and iterations, to optimize model performance, achieving high accuracy in distinguishing between cat and non-cat images.",
            imgUrl: cat_or_non_cat,
        },
        {
            title: "Event Scheduler",
            description: "Developed a mobile app using Kotlin and Jetpack Compose for the Xavier University of Louisiana Computer Science Club to enhance student engagement by delivering personalized event recommendations, streamlining RSVPs, tracking attendance, and enabling real-time event management for moderators.",
            imgUrl: event_scheduler,
        },
        {
            title: "Food Order Site UI",
            description: "Designed the user interface for a responsive Food Order website. This intuitive site lets users browse various restaurant options, place orders for their preferred dishes, and benefit from a seamless experience due to its adaptive layout.",
            imgUrl: food_order,
        },
        {
            title: "Clean Kangaroo",
            description: "Designed the user interface for a prototype application that serves as an on-demand laundry delivery service. The application allows users to register and seamlessly request laundry pick-up and drop-off services at their desired locations. The prototype illustrates a practical solution for an on-demand laundry delivery service, enhancing user convenience and satisfaction.",
            imgUrl: clean_kangaroo,
        },
        {
            title: "NACHOS Multiprogramming OS",
            description: "Extended an Operating System to allow multiple processes to exist in memory at the same time, in addition to enabling user program output. This project utilized a paged memory allocation scheme.",
            imgUrl: nachos,
        },
        {
            title: "Youtube Clone",
            description: "Developed a responsive layout for YouTube's homepage that displays popular videos. Implemented a search functionality and integrated YouTube's API to dynamically fetch and display video content. Clicking on videos redirects users to the corresponding YouTube link, providing a seamless user experience.",
            imgUrl: yt_clone,
        },
    ];

    const visibleProjects = showMore ? projects : projects.slice(0, 9);

    return (
        <section className="project" id="projects">
            <Container>
                <Row>
                    <Col>
                        <TrackVisibility>
                            {({ isVisible }) =>
                                <div className={isVisible ? "animate__animated animate__pulse" : ""}>
                                    <h2>Projects</h2>
                                </div>
                            }
                        </TrackVisibility>
                        <Tab.Container id="projects-tabs" defaultActiveKey="first">
                            <Row className={`projects-grid ${showMore ? "expanded" : ""}`}>
                                {visibleProjects.map((project, index) => {
                                    return (
                                        <ProjectCard
                                            key={index}
                                            {...project} />
                                    )
                                })}
                            </Row>
                        </Tab.Container>

                        {!showMore && (
                            <div className="show-more-container">
                                <div className="fade-overlay"></div>
                                <button className="show-more-btn" onClick={() => setShowMore(true)}>Show More</button>
                            </div>
                        )}
                    </Col>
                </Row>
            </Container>
            <img className="background-image-right" src={colorSharp2} alt="Background"></img>
        </section>
    )
}