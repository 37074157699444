import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ArrowRightCircle } from "react-bootstrap-icons";
import headerImg from "../assets/img/header-img.svg";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { HashLink } from 'react-router-hash-link';

export const Banner = () => {
    const [loopNum, setLoopNum] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const toRotate = ["CS Graduate", "Software Developer", "Web Designer"];
    const [text, setText] = useState('');
    const [delta, setDelta] = useState(100);
    const period = 1500;

    useEffect(() => {
        let ticker = setInterval(() => {
            tick();
        }, delta)

        return () => { clearInterval(ticker) };
    }, [text])

    const tick = () => {
        let i = loopNum % toRotate.length;
        let fullText = toRotate[i];
        let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

        setText(updatedText);

        if (isDeleting) {
            setDelta(50);
        }

        if (!isDeleting && updatedText === fullText) {
            setIsDeleting(true);
            setDelta(period);
        } else if (isDeleting && updatedText === '') {
            setIsDeleting(false);
            setLoopNum(loopNum + 1);
            setDelta(100);
        }
    }

    return (
        <section className="banner" id="home">
            <Container>
                <Row className="align-items-center">
                    <Col xs={12} md={6} xl={7}>
                        <TrackVisibility>
                            {({ isVisible }) =>
                                <div className={isVisible ? "animate__animated animate__pulse" : ""}>
                                    <span className="tagline">Welcome to my Portfolio</span>
                                    <h1>{`Hi, I'm Michael Lambo `}<br></br><span className="wrap">• {text} •</span></h1>
                                    <p>I am a dedicated developer with a strong passion for full-stack development and a commitment to creating impactful software. With hands-on experience with AI frameworks, machine learning, and both web and app development tools, I specialize in designing solutions that enhance user experiences and streamline user workflows, leveraging a wide range of programming languages and technologies.

My goal is to build intuitive, seamless applications that solve real-world problems, all while continuously learning and adapting to the ever-evolving tech landscape</p>
                                    <HashLink to="#connect" style={{ textDecoration: 'none' }}>
                                        <button>
                                            Let's connect <ArrowRightCircle size={25} />
                                        </button>
                                    </HashLink>
                                </div>
                            }
                        </TrackVisibility>
                    </Col>

                    <Col xs={12} md={6} xl={5}>
                        <img src={headerImg} alt="Header Img" />
                    </Col>
                </Row>
            </Container>

        </section>
    )
}