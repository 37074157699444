import { Container, Row, Col } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import cert1 from "../assets/img/deep_learning_1.png";
import cert2 from "../assets/img/deep_learning_2.png";
import cert3 from "../assets/img/deeplearning-3.png";
import cert4 from "../assets/img/deep_learning_4.png";
import cert5 from "../assets/img/figma_cert.png";

export const Certificates = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="certificate" id="certificates">
      <Container>
        <Row>
          <Col>
            <TrackVisibility>
              {({ isVisible }) => (
                <div className={isVisible ? "animate__animated animate__pulse" : ""}>
                  <div className="cert-bx">
                    <h2>Certificates</h2>
                    <Carousel
                      responsive={responsive}
                      infinite={true}
                      className="cert-slider"
                    >
                      <div className="item">
                        <img src={cert1} alt="Certificate 1" />
                        <h5>Neural Networks & Deep Learning</h5>
                      </div>
                      <div className="item">
                        <img src={cert2} alt="Certificate 2" />
                        <h5>Improving Deep Neural Networks</h5>
                      </div>
                      <div className="item">
                        <img src={cert3} alt="Certificate 3" />
                        <h5>Structuring Machine Learning Projects</h5>
                      </div>
                      <div className="item">
                        <img src={cert4} alt="Certificate 4" />
                        <h5>Sequence Models</h5>
                      </div>
                      <div className="item">
                        <img src={cert5} alt="Certificate 5" />
                        <h5>Complete Figma Course</h5>
                      </div>
                    </Carousel>
                  </div>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
