import { Col } from "react-bootstrap";

export const ProjectCard = ({ title, description, imgUrl }) => {
    const stretch_titles = ["Focus Buddy", "NACHOS Multiprogramming OS"];

    return (
        <Col sm={6} md={4}>
            <div className={stretch_titles.includes(title) ? "proj-imgbx" : "proj-imgbx2"}>
                <img src={imgUrl} alt="Project Card" />
                <div className="proj-txtx">
                    <h4>{title}</h4>
                    <span>{description}</span>
                </div>
            </div>
        </Col>
    )
}