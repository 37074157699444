import './Apps.css';
import { NavBar } from './components/NavBar';
import { Banner } from './components/Banner';
import { Skills } from './components/Skills';
import { Projects } from './components/Projects';
import { WorkExperience } from './components/WorkExperience';
import { Certificates } from './components/Certificates';
import { Contact } from './components/Contact';
import { Footer } from './components/Footer';
import { BrowserRouter as Router } from 'react-router-dom'; // Import BrowserRouter
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Router>
    <div className="App">
      <NavBar />
      <Banner />
      <Skills />
      <Projects />
      <WorkExperience />
      <Certificates />
      <Contact />
      <Footer />
    </div>
    </Router>
  );
}

export default App;

